<template>
<div>
  <nav class="navbar navbar-light bg-light">
    <img
      src="../../assets/logo_transparent.png"
      width="70"
      height="70"
      alt="Know Prep Logo"
    />
    <h4>{{ current_section_name }}</h4>
  </nav>
  <div class="heading">
    <h1>Test:{{ test_name }}</h1>
    <h4>Starts at:{{ start_time }}</h4>
  </div>
  <div class="addquestions">
    <label>No of Easy Questions</label>
    <input
      class="placeholder"
      type="number"
      @input="oneInput($event)"
      v-model="easyQues"
      name="easyQues"
    />
    <label>No of Medium Questions</label>
    <input
      class="placeholder"
      type="number"
      @input="onmInput($event)"
      v-model="medQues"
      name="medQues"
    />
    <label>No of Hard Questions</label>
    <input
      class="placeholder"
      type="number"
      @input="onhInput($event)"
      v-model="hardQues"
      name="hardQues"
    />
    <button class="btn btn-primary my-3" @click="shuffle">
      Generate Section
    </button>
    <div class="questions">
      <div class="prev-made-questions">
        <ol type="1">
          <li v-for="(question, index) in filters" :key="index">
            <div class="row" justify-content-between>
              <div class="col my-2 question">{{ question.question }}</div>
              <div class="col-1">({{ question.marks }})</div>
              <div class="col-1">({{ question.difficulty }})</div>
            </div>
            <ol v-if="question.propositions" type="A">
              <li
                v-for="(item, ind) in question.propositions"
                :key="ind"
                :style="
                  item['props'] == question.correct_option
                    ? { color: '#24cb24' }
                    : ''
                "
              >
                {{ item["props"] }}
              </li>
            </ol>
            <div class="col-1">
              <br />
              Explanation ({{ question.explanation }})
            </div>
          </li>
        </ol>
      </div>
    </div>
    <button class="btn btn-success my-3" @click="saveIT">Save Section</button>
  </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import store from "@/store";

export default {
  props: ["test_id", "page_id", "test_name", "start_time"],

  async mounted() {
    db.collection("tests" + this.page_id)
      .doc(this.test_id)
      .get()
      .then(doc => {
        this.section = doc.data().sections;
      });
    var sect = db.collection("questionBank");
    sect = await sect.get();
    sect = sect.docs.map(doc => doc.data());
    this.questionBank = sect;
    this.current_section_name = this.section[0].section_name;
    store.state.loading = false;
  },
  computed: {
    filters() {
      if (this.questions != null)
        return this.questions.filter(question => {
          if (question.section == this.section[0].section_name) return true;
          else return false;
        });
      else return null;
    }
  },
  data() {
    return {
      current_section: 0,
      questions: [],
      section: [],
      current_section_name: "",
      questionBank: [],
      easyQues: 0,
      medQues: 0,
      hardQues: 0,
      id: 0,
      total: 0
    };
  },
  methods: {
    eQ(difficulty) {
      console.log(this.section.length);
      if (this.section.length != 0) {
        var doc = this.section[0];
        return this.questionBank.filter(question => {
          if (
            question.section
              .toLowerCase()
              .indexOf(doc.section_name.toLowerCase()) > -1
          )
            return (
              !difficulty ||
              question.difficulty
                .toLowerCase()
                .indexOf(difficulty.toLowerCase()) > -1
            );
          else return null;
        });
      }
    },
    oneInput(event) {
      const newValue = parseInt(event.target.value);
      var clampedValue = Math.min(newValue, this.eQ("Easy").length);
      clampedValue = Math.max(0, clampedValue);
      this.easyQues = clampedValue;
      this.$forceUpdate();
    },
    onmInput(event) {
      const newValue = parseInt(event.target.value);
      var clampedValue = Math.min(newValue, this.eQ("Medium").length);
      clampedValue = Math.max(0, clampedValue);
      this.medQues = clampedValue;
      this.$forceUpdate();
    },
    onhInput(event) {
      const newValue = parseInt(event.target.value);
      var clampedValue = Math.min(newValue, this.eQ("Hard").length);
      clampedValue = Math.max(0, clampedValue);
      this.hardQues = clampedValue;
      this.$forceUpdate();
    },
    shuffle() {
      var E = "Easy";
      var M = "Medium";
      var H = "Hard";
      var doc = this.section[0];
      var easy = this.questionBank.filter(question => {
        if (
          question.section
            .toLowerCase()
            .indexOf(doc.section_name.toLowerCase()) > -1
        )
          return (
            !E ||
            question.difficulty.toLowerCase().indexOf(E.toLowerCase()) > -1
          );
        else return null;
      });
      var med = this.questionBank.filter(question => {
        if (
          question.section
            .toLowerCase()
            .indexOf(doc.section_name.toLowerCase()) > -1
        )
          return (
            !M ||
            question.difficulty.toLowerCase().indexOf(M.toLowerCase()) > -1
          );
        else return null;
      });
      var hard = this.questionBank.filter(question => {
        if (
          question.section
            .toLowerCase()
            .indexOf(doc.section_name.toLowerCase()) > -1
        )
          return (
            !H ||
            question.difficulty.toLowerCase().indexOf(H.toLowerCase()) > -1
          );
        else return null;
      });
      this.questions = this.getRandom(easy, parseInt(this.easyQues))
        .concat(this.getRandom(med, parseInt(this.medQues)))
        .concat(this.getRandom(hard, parseInt(this.hardQues)));
    },
    getRandom(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    saveIT() {
      swal({
        title: "Are you sure?",
        text:
          "Once sections is saved you would not be able to access it from this page",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          swal("Yep! Section has been saved!", {
            icon: "success"
          });
          var outdata = this.questions;
          var question = "";
          var marks = 0;
          var id = this.id;
          var explanation = "";
          var correct = "";
          var section = 0;
          var total = this.total;
          var difficulty = "";
          var subType = "";
          for (var q in outdata) {
            question = outdata[q].question;
            marks = outdata[q].marks;
            total += marks;
            explanation = outdata[q].explanation;
            difficulty = outdata[q].difficulty;
            section = this.section[0].section_name;
            subType = outdata[q].subType;
            if (outdata[q].correct_option != null) {
              correct = outdata[q].correct_option;
              if (subType == 1) {
                var options = outdata[q].propositions;
                db.collection("tests" + this.page_id)
                  .doc(this.test_id)
                  .collection("questions")
                  .add({
                    question: question,
                    marks: +marks,
                    correct: correct,
                    id: id,
                    section: section,
                    explanation: explanation,
                    difficulty: difficulty,
                    subType: subType,
                    options: options //all options
                  });
              } else {
                db.collection("tests" + this.page_id)
                  .doc(this.test_id)
                  .collection("questions")
                  .add({
                    question: question,
                    marks: +marks,
                    correct: correct,
                    id: id,
                    section: section,
                    explanation: explanation,
                    difficulty: difficulty,
                    subType: subType
                  });
              }
            } else {
              db.collection("tests" + this.page_id)
                .doc(this.test_id)
                .collection("questions")
                .add({
                  question: question,
                  section: section,
                  marks: +marks,
                  explanation: explanation,
                  difficulty: difficulty,
                  id: id,
                  subType: subType
                });
            }
            db.collection("tests" + this.page_id)
              .doc(this.test_id)
              .update({
                total: total
              });
            this.total = total;
            id++;
          }
          this.id = id;
          if (this.section.length == 1)
            this.$router.push({
              name: "EditTest",
              params: { test_id: this.test_id, page_id: this.page_id }
            });
          this.section.shift();
          while (
            this.eQ("Easy").length == 0 &&
            this.eQ("Medium").length == 0 &&
            this.eQ("Hard").length == 0
          ) {
            if (this.section.length == 1)
              this.$router.push({
                name: "EditTest",
                params: { test_id: this.test_id, page_id: this.page_id }
              });
            this.section.shift();
          }
          this.current_section_name = this.section[0].section_name;
          this.current_section++;
          this.easyQues = 0;
          this.medQues = 0;
          this.hardQues = 0;
        } else {
          swal("Your Sections is notsaved!");
        }
      });
    }
  }
};
</script>
<style scoped>
.placeholder {
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(122, 0, 122, 0.349) inset;
  width: 70%;
}
.placeholder:focus {
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(122, 0, 122, 0.349);
}
.question {
  font-size: 20;
  font-weight: 600;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}

.questions {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.349) inset;
  padding: 5vmin;
  text-align: left;
}

.addquestions {
  width: 70%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.349);
  box-sizing: border-box;
  padding: 10vmin;
  transform: translate(0%, 5%);
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto;
  justify-content: center;
}
button {
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 40%;
}
.btn-primary {
  background-color: #ff7a7a;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.btn-success {
  background-color: #ff3c3c;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}

body {
  overflow-x: hidden;
}
@media only screen and (max-width: 1024px) {
  .addquestions {
    width: 100vmin;
    padding: 10vmin;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .questions {
    width: 100vmin;
    padding: 10vmin;
    overflow-x: hidden;
  }
}
.heading {
  width: max-content;
  margin-left: 20%;
}
.heading > h1 {
  font-family: "Londrina Solid", cursive;
  font-size: xx-large;
}
.heading > h4 {
  font-family: "Bodoni MT";
  font-size: medium;
}
</style>
